import imgTV from '../../../src/images/data/NEWS.png';
import ios from '../../../src/images/data/app/sky-news/skynews-ios.png';
import and from '../../../src/images/data/app/sky-news/skynews-android.png';
import ico from '../../../src/images/data/My_Sky_News_logo_Retail_App_Assets_IT.png';

import logo from '../../../src/images-static/logo-colored.jpg';

const app = {
    data : {
        wp: {
            app: {
                dataHeader : {
                    home:true, 
                    back:true,
                    logo:logo,
                },
                links :
                    {
                    prev : '/apps/sky-go', 
                    next : '/apps/sky-sports'
                },
                options : [
                    {
                        id: 'q-app',
                        ico : ico,
                        text : '<h1>Sky News</h1><p>Download our app for award-winning journalism and breaking news stories from the UK and around the world. We take you to the heart of the stories that shape our world, offering live events, eyewitness reports, expert analysis and opinion.</p>',
                        qr : [
                            {
                                img : ios,
                                code : 'ios',
                                link : '',
                                label : 'iOS'
                            },
                            {
                                img : and,
                                code : 'and',
                                link : '',
                                label : 'Android'
                            },
                            
                        ],
                        image: {
                            mediaItemUrl:imgTV
                        },
                    },
                ]
            }
        },

    }
}

export default app;